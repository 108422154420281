import { ref, watch } from "@vue/composition-api";

// Notification
import slideShowRepository from "@/abstraction/repository/slideShowRepository";

const repository = new slideShowRepository();
export default function usemoodList() {
    const refplaylistListTable = ref(null);
    const playlists = ref([]);
    const perPage = ref(10);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const currentPage = ref(1);
    const searchQuery = ref("");
    const isSortDirDesc = ref(true);
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const pagination = ref({});

    const refetchData = () => {
        refplaylistListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
        refetchData();
    });

    // Table Handlers
    const tableColumns = [{ key: "name", sortable: true }, { key: "type", sortable: true }, { key: "type_name", sortable: true }, { key: "status", sortable: true }, { key: "click_count", sortable: true }, { key: "actions" }];

    const storeSlideShow = async (data) => {
        try {
            return await repository.addSlideShow(data);
        } catch (e) {
            return false;
        }
    };

    const storeActiveSlideShows = async (data) => {
        try {
            return await repository.addActiveSlideShow(data);
        } catch (e) {
            return false;
        }
    };

    const showSlideshow = async (id) => {
        try {
            return await repository.show(id);
        } catch (e) {
            return false;
        }
    };

    const updateSlideshow = async (id, data) => {
        try {
            return await repository.update(id, data);
        } catch (e) {
            return false;
        }
    };

    const fetchSlideShowList = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const playlistsList = await repository.index({ filters, pagination: paginate });
        playlists.value = playlistsList.data;
        pagination.value = playlistsList.pagination;
        return playlists;
    };
    const fetchActiveSlideShows = async () => {
        const activeLists = await repository.activeIndex();
        // playlists.value = playlistsList.data;
        // pagination.value = playlistsList.pagination;
        return activeLists;
    };
    const deletePlaylist = async (id) => {
        try {
            const response = await repository.delete(id);
            pagination.value.itemsLength--;
            const index = playlists.value.findIndex((x) => x.id === id);
            playlists.value.splice(index, 1);
            return response;
        } catch (e) {
            return false;
        }
    };

    const changePagePlaylist = async (page) => {
        const response = await fetchSlideShowList({ pagination: page });
        return response;
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        tableColumns,
        playlists,
        pagination,
        perPageOptions,
        perPage,
        sortBy,
        currentPage,
        storeSlideShow,
        showSlideshow,
        updateSlideshow,
        fetchSlideShowList,
        deletePlaylist,
        fetchActiveSlideShows,
        storeActiveSlideShows,
    };
}
